import React, { useRef, useEffect, useState } from 'react';
import { Template, BLANK_PDF } from '@pdfme/common';
import { Designer, Form, Viewer } from '@pdfme/ui';
import { text, image, barcodes } from '@pdfme/schemas';

import './CertificateDesign.css'
// import DEFAULT_PDF from '../templates/defaultPdf.template';
import MembersAreaService from '../services/MembersArea.service';
import { useParams } from 'react-router-dom';
import { Button, Col, Icon, Notification, Row, Uploader } from 'rsuite';

const CertificateDesign = (props: any) => {
    let {  document_id } : any = useParams()

    const [template, setTemplate] = useState<any>(null)
    const [certificateTemplateMetadata, setCertificateTemplateMetadata] = useState<any>(null)

    const fontObjList = [
        {
            label: 'PinyonScript',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/PinyonScript-Regular.ttf',
        },
        {
            fallback: false,
            label: 'Roboto',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Roboto-Regular.ttf',
        },
        {
            label: 'Roboto (Bold)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Roboto-Bold.ttf',
        },
        {
            label: 'Roboto (Light)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Roboto-Light.ttf',
        },
        {
            label: 'Roboto Mono',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/RobotoMono-Regular.ttf',
        },
        {
            label: 'Roboto Mono (Bold)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/RobotoMono-Bold.ttf',
        },
        {
            label: 'Roboto Mono (Light)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/RobotoMono-Light.ttf',
        },
        {
            fallback: true,
            label: 'Montserrat',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Montserrat-Regular.ttf',
        },
        {
            label: 'Montserrat (Bold)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Montserrat-Bold.ttf',
        },
        {
            label: 'Montserrat (Light)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Montserrat-Light.ttf',
        },
        {
            label: 'Lato',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Lato-Regular.ttf',
        },
        {
            label: 'Lato (Bold)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Lato-Bold.ttf',
        },
        {
            label: 'Lato (Light)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Lato-Light.ttf',
        },
        {
            label: 'Open Sans',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/OpenSans-Regular.ttf',
        },
        {
            label: 'Open Sans (Bold)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/OpenSans-Bold.ttf',
        },
        {
            label: 'Open Sans (Light)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/OpenSans-Light.ttf',
        },
        {
            label: 'Poppins',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Poppins-Regular.ttf',
        },
        {
            label: 'Poppins (Bold)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Poppins-Bold.ttf',
        },
        {
            label: 'Poppins (Light)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Poppins-Light.ttf',
        },
        {
            label: 'Sora',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Sora-Regular.ttf',
        },
        {
            label: 'Sora (Bold)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Sora-Bold.ttf',
        },
        {
            label: 'Sora (Light)',
            url: 'https://ecompay-s3.us-southeast-1.linodeobjects.com/fonts/Sora-Light.ttf',
        },
    ];

    const getFontsData = async () => {
        const fontDataList = await Promise.all(
            fontObjList.map(async (font) => ({
                ...font,
                data: await fetch(font.url).then((res) => res.arrayBuffer()),
            }))
        );
    
        return fontDataList.reduce((acc, font) => ({ ...acc, [font.label]: font }), {} as any);
    };

    const onChangeTemplate = (evt: any) => {
        console.log(evt)
    }

    const onSaveTemplate = (template?: Template) => {
        if (designer.current) {
            const template = JSON.stringify(designer.current.getTemplate())
            localStorage.setItem(
                "template",
                template
            );
            const currentTemplate = designer.current.getTemplate()

            const response: any = new MembersAreaService().put(currentTemplate, `update/certificate/template_json/${certificateTemplateMetadata?.template?._id}`)

            if(!response.error){
                Notification['success']({
                    title: "Sucesso",
                    description: <p>O template foi salvo com sucesso</p>
                });
            }
        }
    };

    const readFile = (file: File | null, type: 'text' | 'dataURL' | 'arrayBuffer') => {
        return new Promise<string | ArrayBuffer>((r) => {
        const fileReader = new FileReader();
        fileReader.addEventListener('load', (e) => {
            if (e && e.target && e.target.result && file !== null) {
                r(e.target.result);
            }
        });
        if (file !== null) {
            if (type === 'text') {
                fileReader.readAsText(file);
            } else if (type === 'dataURL') {
                fileReader.readAsDataURL(file);
            } else if (type === 'arrayBuffer') {
                fileReader.readAsArrayBuffer(file);
            }
        }
        });
    };

    const designerRef = useRef<HTMLDivElement | any>(null);
    const designer = useRef<Designer | any>(null);

    const cloneDeep = (obj: any) => JSON.parse(JSON.stringify(obj));

    const buildDesigner = () => {
        if (designerRef.current) {
            designer.current = new Designer({
                domContainer: designerRef.current,
                template,
                plugins: { text, image, qrcode: barcodes.qrcode },
            });

            designer.current.onSaveTemplate(onSaveTemplate);
            designer.current.onChangeTemplate(onChangeTemplate);
        }
    };

    const onChangeBasePDF = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files) {
        readFile(e.target.files[0], "dataURL").then(async (basePdf) => {
            if (designer.current) {
            designer.current.updateTemplate(
                Object.assign(cloneDeep(designer.current.getTemplate()), {
                basePdf,
                })
            );
            }
        });
        }
    };

    const loadTemplateFromId = async () => {
        const response: any = await new MembersAreaService().get(`certificate/template/${document_id}`);

        if(response?.template?.templateUrl){
            setCertificateTemplateMetadata(response)
            let templateKey = response?.template?.templateUrl
            templateKey = templateKey.split('/')
            templateKey = templateKey[templateKey.length - 1]
            
            const templateJson = await new MembersAreaService().get(`certificate/template/signed/${templateKey}`);

            setTemplate(templateJson)
        }
    }

    useEffect(() => {
        loadTemplateFromId()
    },[])
    useEffect(() => {
        if(template){
            buildDesigner()
    
            getFontsData().then((font) => {
                if (designerRef.current) {
                designer.current = new Designer({
                    domContainer: designerRef.current,
                    template,
                    options: {
                    font,
                    labels: {
                        addNewField: 'Adicionar elemento', 
                    },
                    theme: {
                        token: {
                            colorPrimary: '#3498FF',
                        },
                    },
                    },
                    plugins: { text, image, qrcode: barcodes.qrcode },
                });
                designer.current.onSaveTemplate(onSaveTemplate);
                }
            });
        }
    }, [template]);

    const fileInputRef: any = useRef(null);

    return <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{height: '55px', width: '100%', backgroundColor: '#3498ff', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <a rel="noreferrer" href="https://ecompay.app" target="_blank"><img style={{marginLeft: '25px'}} width="110px" alt="logo" height="38px" src="https://ecompay.app/static/media/logo.a8f08aab.svg"></img></a>
                <h2 style={{marginLeft: '25px', color: 'white', fontWeight: 'bold'}}>Editar Template</h2>
            </div>
            <div style={{width: '450px'}}>
                <Row>

                    <Col lg={8}>
                            <input ref={fileInputRef} style={{display: 'none'}} id="file-pdf-uploader"  type="file"  accept="application/pdf" onChange={onChangeBasePDF} />
                            <label htmlFor="file-pdf-uploader" style={{cursor: 'pointer'}}>
                                <Button appearance='default' onClick={() => fileInputRef.current.click()} >
                                    <Icon style={{marginRight: '5px'}} icon="upload" />
                                    Enviar PDF Base
                                </Button>
                            </label>
                    </Col>
                    <Col lg={8}><Button appearance='default' onClick={()=> {onSaveTemplate()}}> <Icon style={{marginRight: '5px'}} icon="save" />Salvar template</Button></Col>
                    <Col lg={8}><Button appearance='default' onClick={()=> {window.open(`${process.env.REACT_APP_API_URL}/members/certificate/template/preview/${certificateTemplateMetadata?.template?._id}`)}}> <Icon style={{marginRight: '5px'}} icon="eye" />Pré-visualizar</Button></Col>
                </Row>
            </div>
        </div>
        <div ref={designerRef}></div>
        
    </div>;
}

export default CertificateDesign;
