import React from 'react';
import logo from './logo.svg';
import CertificateDesign from './components/CertificateDesign.component';
import LoginComponent from './components/Login.component';


import {
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";

import 'rsuite/dist/styles/rsuite-default.css';

function App() {
  return (
    <HashRouter basename="/">
      <Switch>
        <Route path="/auth/:token/:document_id">
          <LoginComponent />
        </Route>
        <Route path="/edit/:document_id">
          <CertificateDesign />
        </Route>
        <Route exact={true} path="/">
          <CertificateDesign />
        </Route>
      </Switch>
    </HashRouter> );
}

export default App;
