import { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"

const LoginComponent = (props: any) => {
    let { token, document_id } : any = useParams()
    const history: any = useHistory()

    useEffect(()=>{
        if(token){
            localStorage.setItem('access_token', token)
            if(document_id){
                history.push(`/edit/${document_id}`)
            }
        }else{
            console.error('Forbiden')
        }
    },[])
    return <div></div>
}

export default LoginComponent